<template>
	<span>
		<validation-provider
			mode="lazy"
			:name="slug"
			:rules="rules"
			:class="[ css ]"
			v-slot="{ errors }"
			tag="div"
			class="line"
		>
			<label :for="slug" class="label">{{ `${label}${required('*')}` }}</label>
			<select :id="slug" :data-id="formElementId" :name="slug" v-model="value" class="select-css">
				<option
					v-for="(item, index) in selectItems"
					:key="`option-${slug}-${index}`"
					:value="item.value">{{ item.option }}</option>
			</select>
			<span v-if="errors.length" class="error select-input">{{ errors[0] }}</span>
		</validation-provider>
	</span>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mixinFormElements } from '@/js/mixins/form-elements'

export default {
	name: 'ContentFormSelectinput',
	mixins: [ mixinFormElements ],
	components: {
		ValidationProvider,
	},
	data() {
		return {
			open: false,
		}
	},
	methods: {
		select(value) {
			Object.assign(this, { value })
		}
	},
	updated() {
		this.open = false
	},
}
</script>

<style scoped lang="stylus">
	@import '../../../assets/css/account-creation'
	@import '../../../assets/css/custom-select'

	.values
		display flex
		flex-direction column
		background-color white
		border-radius 20px
		color black
		width 250px
		max-height 300px
		cursor pointer
	.closed
		height 30px
	.open
		height 200px
	.selected
		background-color black
		color white
</style>
