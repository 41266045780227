<template>
	<div class="view view-login">
		<!-- <div class="bg"></div> -->
		<world-map key="world-map-start" @load="set_loaded" :show_cities="false" />

		<div class="scroll" v-scrollbar>
			<div>
				<div class="ticket-wrap">
					<div class="ticket">
						<component :is="'page-' + page" />

						<div class="ticket-bottom">
							<div class="plane"></div>
							<div class="barcode"></div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import WorldMap from '@/components/world/world-map'
import PageMenu from '@/components/start/page-menu'
import PageLogin from '@/components/start/page-login'
import PageSignup from '@/components/start/page-signup'
import PageForgot from '@/components/start/page-forgot'
import PagePlay from '@/components/start/page-play'
import PageForgotSent from '@/components/start/page-forgot-sent'
import PageSignupSent from '@/components/start/page-signup-sent'

export default {
	name: 'view-login',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		page() {
			if ([ 'login', 'signup', 'forgot', 'play', 'forgot-sent', 'signup-sent' ].includes(this.$route.name)) return this.$route.name
			return 'menu'
		}
	},
	methods: {
		set_loaded() {
			this.$store.commit('EDIT_APP_STATE', { main_loader: false })
		}
	},
	// mounted() {
	// 	this.$store.commit('EDIT_APP_STATE', { main_loader: false })
	// },
	components : { WorldMap, PageMenu, PageLogin, PageSignup, PageForgot, PagePlay, PageForgotSent, PageSignupSent }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.view-login
	height 100%
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background url(../assets/img/worldmap@2x.png) center center no-repeat
		background-size cover
	>>> .world-map
		absolute 0
	.scroll
		height 100%
		width 100%

		.ticket-wrap
			height 100%
			width 100%
			flex center center

	.ticket
		flex space-between center
		flex-direction column
		width calc(100% - 40px)
		min-height 550px
		max-width 400px
		background-color dark
		border-radius 32px
		box-shadow 0px 10px 6px alpha(#000, 30%)

		>>> .page
			padding 48px 32px
			width 100%
			.page-title
				height 32px
				margin 0 0 32px 0
				flex center center
				font-weight 500
				font-size 2.2rem
				color #fff
				text-transform uppercase
			.form
				height auto
				.line
					margin 0 0 16px 0
					.label
						display block
						margin 0 0 2px 16px
					.input
						height 48px
						width 100%
						padding 0 24px
						background-color #eee
						border none
						border-radius 40px
						border 2px solid transparent
						box-shadow 0 2px 4px alpha(#000, 25%)
						outline none
						font inherit
						color dark
						line-height 40px
						font-weight 500
						appearance none
						&:focus
							border-color green
							box-shadow 0 2px 4px alpha(#000, 75%)
							background-color #fff
					> .error
						display block
						margin 4px 0 0 0
						padding 3px 0 3px 32px
						background url(../assets/img/alert-circle.svg) left center no-repeat
						background-size 24px 24px
						line-height 18px
						color red
						font-weight 500
					.info
						color alpha(#fff, 75%)
						text-align center
						font-size 1.6rem
						line-height 20px
				.ui-button
					box-shadow 0 2px 4px alpha(#000, 25%)
				.buttons
					flex space-between center
					&.centered
						flex center center
					.ui-button
						width calc(50% - 8px)
						.button
							padding 0
							font-weight 700
							text-transform uppercase
		.ticket-bottom
			width 100%
			height 160px
			border-top 3px dashed gray
			display flex
			align-items center
			justify-content center
			flex-direction column
			.plane
				width 24px
				height 24px
				margin 0 0 8px 0
				background url(../assets/img/plane.svg) center center no-repeat
				background-size auto 100%
			.barcode
				width 100%
				height 48px
				background url(../assets/img/barcode.svg) center center no-repeat
				background-size auto 100%


@media (max-height: 750px)
	.view-login
		.scroll
			.ticket-wrap
				display block
				.ticket
					margin 50px auto

</style>
