<template>
	<div class="page page-signup-sent">
		<div class="page-title">Courriel envoyé</div>
		<form class="form">

			<p class="text">Un courriel vous a été envoyé pour confirmer la création de votre compte.</p>

			<div class="buttons">
				<ui-button :to="{ name: 'start' }" color="green">Ok</ui-button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'page-signup-sent',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([  ])
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.view-login .ticket .page.page-signup-sent
	.text
		font-size 1.8rem
		text-align center
		color #fff
	.buttons
		flex center center
		text-align center
		>>> .ui-button
			margin 0 auto


</style>
