<template>
	<div class='page page-login'>
		<div class='page-title'>Connexion</div>
		<ValidationObserver
			ref='login'
			v-slot='{ handleSubmit }'
		>
			<form
				class='form'
				method='post'
				@submit.prevent='handleSubmit(login)'
        @keyup.enter='handleSubmit(login)'
			>
				<validation-provider
					name='email'
					rules='required|email'
					v-slot='{ errors }'
					tag='div'
					class='line'
				>
					<input v-model='email' type='email' class='input' placeholder='Courriel'>
					<span v-if='errors.length' class='error'>{{ errors[0] }}</span>
				</validation-provider>
				<validation-provider
					name='password'
					rules='required'
					v-slot='{ errors }'
					tag='div'
					class='line'
				>
					<input v-model='password' type='password' class='input' placeholder='Mot de passe'>
					<span v-if='errors.length' class='error'>{{ errors[0] }}</span>
				</validation-provider>

        <span v-if="invalidCredentials" class="login error">Informations invalides</span>

				<div class='line'>
					<ui-button :to="{ name: 'forgot' }" width="100%" color="dark">Mot de passe oublié?</ui-button>
				</div>

				<div class='buttons'>
					<ui-button v-hammer:tap='() => $router.go(-1)' color='red'>Retour</ui-button>
					<ui-button v-hammer:tap='() => handleSubmit(login)' color='green'>Confirmer</ui-button>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { login } from '@/js/jwt/jwt'

export default {
	name: 'page-login',
	components: {
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			email: null,
			password: null,
      invalidCredentials: false,
		}
	},
  watch: {
	  email(n, o) {
	    if (!n && o) return
	    this.invalidCredentials = false
    },
	  password(n, o) {
      if (!n && o) return
	    this.invalidCredentials = false
	  }
  },
	computed: {},
	methods: {
		async login() {
			const {email, password} = this
			if (!email || !password) return
      const isUserLogged = await login({ email, password })
      this.loading = false
      if (isUserLogged) {
        this.$store.commit('EDIT_APP_STATE', { main_loader: true })
        await this.$store.dispatch('get_user')
        await this.$store.dispatch('noNeedForStart')
        this.$nextTick(() => {
          this.$router.push({ name: 'world' })
        })
      } else
        this.$refs.login.reset()
        this.invalidCredentials = true
        this.email = null
        this.password = null
		}
	},
}
</script>

<style lang='stylus' scoped>
@import '../../assets/css/variables'
@import '../../assets/css/commun'


</style>
