<template>
	<span>
		<validation-provider
			mode="lazy"
			:name="slug"
			:rules="rules"
			:class="[ css ]"
			v-slot="{ errors }"
			tag="div"
		>
			<div class="line">
				<input :id="slug" :data-id="formElementId" v-model="value" type="checkbox" class="checkbox-hcp">
				<label :for="slug" v-html="`${label}${required('*')}`"></label>
				<span v-if="errors.length" class="error checkbox">{{ errors[0] }}</span>
			</div>
		</validation-provider>
	</span>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mixinFormElements } from '@/js/mixins/form-elements'

export default {
	name: 'ContentFormAcceptinput',
	mixins: [ mixinFormElements ],
	components: {
		ValidationProvider,
	},
}
</script>

<style scoped lang="stylus">
	@import '../../../assets/css/custom-checkbox.styl'
</style>
