<template>
	<div class='page page-forgot'>
		<div class='page-title'>Mot de passe oublié?</div>
		<ValidationObserver
			ref='login'
			v-slot='{ handleSubmit }'
		>
			<form
				class='form'
				method='post'
				@submit.prevent='handleSubmit(forgot)'
			>
				<validation-provider
					name='email'
					rules='required|email'
					v-slot='{ errors }'
					tag='div'
					class='line'
				>
					<input v-model='email' type='text' class='input' placeholder='Courriel'>
					<span v-if='errors.length' class='error'>{{ errors[0] }}</span>
				</validation-provider>

				<div class='line'>
					<p class='info'>Un mot de passe temporaire sera envoyé à l’adresse courriel associée à votre compte.</p>
				</div>

				<div class='buttons'>
					<ui-button v-hammer:tap='() => $router.go(-1)' color='red'>Retour</ui-button>
					<ui-button v-hammer:tap='() => handleSubmit(forgot)' color='green'>Envoyer</ui-button>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'page-forgot',
	data() {
		return {
			email: ''
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver
	},
	computed: {},
	methods: {
		async forgot() {
			const {email} = this
			if (!email) return
			const variables = { email }
			if(await Apollo.mutate('REQUEST_PASSWORD_RESET', variables) === true) {
				this.$router.push({ name: 'forgot-sent'})
			}
		}
	}
}
</script>

<style lang='stylus' scoped>
@import '../../assets/css/variables'



</style>
