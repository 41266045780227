<template>
	<div
		v-html="html"
		class="ql-align-center"
		:class="[ css ]"
	></div>
</template>

<script>
import { mixinFormElements } from '@/js/mixins/form-elements'
import { allBeginsWith } from '@/js/utils/utils'

export default {
	name: 'ContentText',
	mixins: [ mixinFormElements ],
	data() {
		return {
			test: {
				'background-color': 'black'
			}
		}
	},
	computed: {
		css() {
			const { $props:{ data:{ tags = []} = {} } = {}} = this
			return (allBeginsWith(tags, 'CSS-')[0] || '').replace('CSS-', '')
		}
	},
}
</script>

<style scoped lang="stylus">
	.ql-align-center
		text-align center
</style>
