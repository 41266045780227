<template>
	<span>
		<div
			v-for="(group, index) in contentTextsGroups"
			:key="`display-group-${index}`"
			:class="css(group)"
			class="display"
		>
			<div
				v-for="(entry, index) in getFormElement(group)"
				:key="`display-entry-${index}`"
			>
				<component
					:is="getElementType(entry)"
					:data="entry"
					:context="context"
					@tap="tap"
				/>
			</div>
		</div>
			<div class="line buttons centered">
				<ui-button type="login" color="red" v-hammer:tap="() => back()">Retour</ui-button>
			</div>
	</span>
</template>

<script>
import { mixinForms } from '@/js/mixins/forms'

export default {
	name: 'displaySignUp',
	mixins: [ mixinForms ],
	methods: {
		back() {
			this.$store.dispatch('removeAccessToken')
			this.$router.push({ name: 'start' })
		},
	},
}
</script>

<style lang="stylus">
	@import '../../assets/css/forms-base'

	.form-content-text-themed
		color white
	.form-btn-themed
		background-color #61789E
		margin-bottom 20px
</style>
