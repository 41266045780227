<template>
	<div
		v-html="html"
		v-hammer:tap="()=> tap()"
		class="form-btn-base form-btn-themed"
	></div>
</template>

<script>
import { mixinFormElements } from '@/js/mixins/form-elements'
import { allBeginsWith } from '@/js/utils/utils'

export default {
	name: 'formBtn',
	mixins: [ mixinFormElements ],
	methods: {
		tap() {
			const { BTN, $props: { data: { tags = [] } = {} } = {} } = this
			this.$emit('tap', (allBeginsWith(tags, BTN)[0] || '').replace(BTN, ''))
		},
	},
}
</script>
