<template>
	<span>
		<validation-provider
			mode="lazy"
			:name="slug"
			:rules="rules"
			:class="[ css ]"
			v-slot="{ errors }"
			tag="div"
			class="line"
		>
			<label :for="slug" v-html="`${label}${required('*')}`" class="label"></label>
			<input :id="slug" :data-id="formElementId" v-model="value" :type="inputType()" class="input">
			<span v-if="errors.length" class="error text-input">{{ errors[0] }}</span>
		</validation-provider>
	</span>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mixinFormElements } from '@/js/mixins/form-elements'

export default {
	name: 'ContentFormTextinput',
	mixins: [ mixinFormElements ],
	components: {
		ValidationProvider,
	},
	data() {
		return {
			value: null
		}
	},
	updated() {
		this.value = null
	},
}
</script>

<style scoped lang="stylus">
	@import '../../../assets/css/account-creation'
</style>
